'use client';

import { BodyLinkV3, TypographyV3 } from '@jouzen/ecom-components';
import type { RichTranslationValues } from 'next-intl';
import type { ReactNode } from 'react';

const getLocaleFormatting: RichTranslationValues = {
  // In Lokalise, these tokens are simple text replacements for values wrapped in curly braces like {tm}
  r: String.fromCharCode(174),
  tm: String.fromCharCode(8482),
  // Simple HTML tags (i.e. <b>bold text</b>)
  break: function breakTag() {
    return <br />;
  },
  breakLg: function breakLg() {
    return <br className="hidden lg:block" />;
  },
  wbreak: function wbreakTag() {
    return <wbr />;
  },
  strong: function strong(chunks: ReactNode) {
    return <strong>{chunks}</strong>;
  },
  b: function b(chunks: ReactNode) {
    return <strong>{chunks}</strong>;
  },
  em: function em(chunks: ReactNode) {
    return <em className="font-serif italic">{chunks}</em>;
  },
  i: function i(chunks: ReactNode) {
    return <em>{chunks}</em>;
  },
  u: function u(chunks: ReactNode) {
    return <span className="underline">{chunks}</span>;
  },
  sup: function sup(chunks: ReactNode) {
    return <sup>{chunks}</sup>;
  },
  li: function li(chunks: ReactNode) {
    return <li>{chunks}</li>;
  },
  sr: function sr(chunks: ReactNode) {
    return <span className="sr-only">{chunks}</span>;
  },
  p: function p(chunks: ReactNode) {
    return <TypographyV3 color="inherit">{chunks}</TypographyV3>;
  },
  // Japanese-specific formatting for {w} characters
  w: function w(chunks: ReactNode) {
    return <>{chunks}</>;
  },
  uppercase: function uppercase(chunks: ReactNode) {
    return <span className="uppercase">{chunks}</span>;
  },

  // React Components & more complex HTML tag usage
  Link: function Link(href: ReactNode): JSX.Element {
    return <BodyLinkV3 href={href as string}>{href}</BodyLinkV3>;
  },

  ExternalLink: function ExternalLink(href: any): JSX.Element {
    return (
      <BodyLinkV3 href={href[0] as string} target="_blank" rel="noreferrer">
        {href}
      </BodyLinkV3>
    );
  },

  EmailLink: function EmailLink(email: ReactNode): JSX.Element {
    return <BodyLinkV3 href={`mailto:${email as string}`}>{email}</BodyLinkV3>;
  },

  HomeLink: function HomeLink(chunks: ReactNode): JSX.Element {
    return (
      <BodyLinkV3 href="/" color="inherit">
        {chunks}
      </BodyLinkV3>
    );
  },

  TermsLink: function TermsLink(text: ReactNode): JSX.Element {
    return <BodyLinkV3 href="/terms-and-conditions">{text}</BodyLinkV3>;
  },

  IpNoticeLink: function IpNoticeLink(text: ReactNode): JSX.Element {
    return <BodyLinkV3 href="/intellectual-property-notice">{text}</BodyLinkV3>;
  },
};

export default getLocaleFormatting;
