/* eslint-disable no-process-env  */
/* eslint-disable no-console */
'use client';

import { LocalOverrideAdapter } from '@statsig/js-local-overrides';
import {
  type StatsigOptions,
  StatsigProvider,
  type StatsigUser,
  useClientBootstrapInit,
} from '@statsig/react-bindings';
import { useEnvContext } from 'next-runtime-env';
import type { PropsWithChildren } from 'react';

interface StatsigClientProviderProps extends PropsWithChildren {
  readonly user: StatsigUser;
  readonly data?: string;
  readonly optionOverrides?: Partial<StatsigOptions>;
}

export default function StatsigProviderWrapper({
  user,
  data,
  children,
  optionOverrides,
}: StatsigClientProviderProps): JSX.Element {
  const { NEXT_PUBLIC_STATSIG_CLIENT_KEY, NEXT_PUBLIC_OURA_ENV } =
    useEnvContext();
  if (!NEXT_PUBLIC_STATSIG_CLIENT_KEY) {
    throw new Error(
      'Missing NEXT_PUBLIC_STATSIG_CLIENT_KEY environment variable.',
    );
  }

  if (!NEXT_PUBLIC_OURA_ENV) {
    throw new Error('Missing NEXT_PUBLIC_OURA_ENV environment variable.');
  }

  const stableId = user.customIDs?.stableID;

  if (!stableId) {
    console.error('missing stable id. Statsig will not bootstrap properly');
  }

  const options: StatsigOptions = {
    environment: { tier: `${NEXT_PUBLIC_OURA_ENV}` },
    networkConfig: {
      preventAllNetworkTraffic: process.env.NODE_ENV === 'test',
    },
    ...(NEXT_PUBLIC_OURA_ENV !== 'production' && {
      overrideAdapter: new LocalOverrideAdapter(),
    }),
    ...optionOverrides,
  };

  const client = useClientBootstrapInit(
    NEXT_PUBLIC_STATSIG_CLIENT_KEY,
    user,
    data ?? '{}',
    options,
  );

  return <StatsigProvider client={client}>{children}</StatsigProvider>;
}
