'use client';

import { usePathname } from 'next/navigation';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@/utils/sessionStorage';

type PathVisits = Record<string, number>;
export interface FraudPreventionContextValues {
  pathVisits: PathVisits;
}

export const FraudPreventionContext =
  createContext<FraudPreventionContextValues | null>(null);

const FraudPreventionProvider = ({ children }: PropsWithChildren) => {
  const [pathVisits, setPathVisits] = useState<PathVisits>({});
  const pathname = usePathname();

  useEffect(() => {
    if (!pathname) return;

    const visits = getPathVisits();
    const visitCount = visits[pathname] ?? 0;
    setPathVisits(updatePathVisitCount(pathname, visitCount + 1));
  }, [pathname]);

  const values: FraudPreventionContextValues = useMemo(
    () => ({
      pathVisits,
    }),
    [pathVisits],
  );

  return (
    <FraudPreventionContext.Provider value={values}>
      {children}
    </FraudPreventionContext.Provider>
  );
};

export const useFraudPreventionContext = () =>
  useContext(FraudPreventionContext);

export default FraudPreventionProvider;

const SESSION_STORAGE_KEY = 'path_visits';

function getPathVisits(): PathVisits {
  const visits = readFromSessionStorage(SESSION_STORAGE_KEY);
  return (visits ? JSON.parse(visits) : {}) as PathVisits;
}

function updatePathVisitCount(
  pathname: string,
  visitCount: number,
): PathVisits {
  const visits = getPathVisits();
  visits[pathname] = visitCount;
  writeToSessionStorage(SESSION_STORAGE_KEY, JSON.stringify(visits));
  return getPathVisits();
}
