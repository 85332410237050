import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/AkkuratLL-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/AkkuratLL-Light.woff2\",\"weight\":\"300\",\"style\":\"light\"}],\"variable\":\"--font-sans\"}],\"variableName\":\"AkkuratLL\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/PPEditorialNew-Ultralight.woff2\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../fonts/PPEditorialNew-UltralightItalic.woff2\",\"weight\":\"200\",\"style\":\"italic\"}],\"variable\":\"--font-serif\"}],\"variableName\":\"EditorialNew\"}");
;
import(/* webpackMode: "eager" */ "/app/src/analytics/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/LegacyEnvVariables/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/LocaleLinkRewriter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/SentryInit/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/CartProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/FeatureFlagsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/FraudPreventionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/GeoProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/MotionConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/NextIntlClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/OuraConsentManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/ProductsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/StatsigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/UiProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/prefetch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/scripts.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/global-style.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/utils/getLocaleFormatting.tsx");
