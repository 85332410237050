'use client';

/**
 * This hacky component rewrites the cart and my-account links to include the locale
 * because those routes are in ourafront and the locale rewriting does not work
 * on those routes
 * */

import { useLocale } from 'next-intl';
import { useEffect } from 'react';

import { getLegacyEnvVariable } from '../LegacyEnvVariables/util';

const LocaleLinkRewriter = () => {
  const locale = useLocale();
  const NEXT_PUBLIC_ROOT_URL = getLegacyEnvVariable('NEXT_PUBLIC_ROOT_URL')!;

  // handle my-account links
  useEffect(() => {
    traverseLinks(locale, '/my-account', NEXT_PUBLIC_ROOT_URL);
  }, [locale, NEXT_PUBLIC_ROOT_URL]);

  // Handle cart links
  useEffect(() => {
    traverseLinks(locale, '/cart', NEXT_PUBLIC_ROOT_URL);
  }, [locale, NEXT_PUBLIC_ROOT_URL]);
  return null;
};

const traverseLinks = (
  locale: string,
  hrefToMatch: string,
  rootUrl: string,
) => {
  const links = document.querySelectorAll('a[href^="/"]');
  links.forEach((link) => {
    const href = link.getAttribute('href');
    if (href && href === hrefToMatch) {
      link.setAttribute('href', `${rootUrl}/${locale}${href}`);
    }
  });
};

export default LocaleLinkRewriter;
